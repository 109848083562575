<!-- 心理咨询配置 -- 咨询时间配置 -- 新增 -->
<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding-top: 35rem;padding-left: 60rem">
      <h4>咨询基础配置</h4>
      <br>
      <el-form ref="form" :model="formData" label-width="100rem" :rules="rules">
        <div>
          <el-form-item label="校区" prop="school_id">
            <el-select :popper-append-to-body="false" v-model="formData.school_id" @change="schoolChange">
              <el-option v-for="item in schoolConfig" :key="item.id" :label="item.school_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="咨询周期" prop="term_id">
            <el-select :popper-append-to-body="false" v-model="formData.term_id">
              <el-option v-for="item in periodConfig" :key="item.id" :label="item.term_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <div class="flex">
            <el-form-item class="yyy" label="咨询时间" prop="schedule" required=""></el-form-item>
            <div class="yyy1">
              <div class="knowledge" v-for="(item, index) in formData.schedule" :key="index">
                <div class="knowledge_box">
                  <p style="margin-right: 20rem">{{ index + 1 }}</p>
                  <el-form-item :prop="'schedule.'+index+'.week_range'" label-width="0" :rules="[{required:true,message:'请选择时间',trigger:'change'}]">
                    <el-select :popper-append-to-body="false" multiple="" collapse-tags="" @change="dayChange($event,index)" v-model="item.week_range">
                      <el-option v-for="item in dayConfig" :key="item.value" :label="item.label" :value="item.value">
                    </el-option></el-select>
                  </el-form-item>
                  <div class="flex flex-align yyy2" style="margin-left: 40rem">
                    <el-form-item :prop="'schedule.'+index+'.start_at'" label-width="0" :rules="[{required:true,message:'请选择开始时间',trigger:'change'}]">
                      <el-time-picker v-model="item.start_at" value-format="HH:mm" format="HH:mm" :default-value="startDefaultValue" @change="(e) => {item.end_at = e}" placeholder="开始时间">
                      </el-time-picker>
                    </el-form-item>
                    <div style="margin: 0 20rem">至</div>
                    <el-form-item :prop="'schedule.'+index+'.end_at'" label-width="0" :rules="[{required:true,message:'请选择结束时间',trigger:'change'}]">
                      <el-time-picker v-model="item.end_at" value-format="HH:mm" format="HH:mm" :picker-options="{selectableRange: (item.start_at || '00:00') + ':00 - 23:59:00'}" placeholder="结束时间">
                      </el-time-picker>
                    </el-form-item>
                  </div>
                </div>
                <div class="add_knowledge">
                  <el-button icon="el-icon-delete" circle="" @click="deleteTime(index)"></el-button>
                  <el-button icon="el-icon-plus" circle="" @click="addTime" v-if="formData.schedule.length - 1 === index &amp;&amp; formData.schedule.length !== 20"></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
import moment from "moment";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      // 校区配置
      schoolConfig: [],
      // 周期配置
      periodConfig: [],
      // 天数配置
      dayConfig: [
        {label: '每天', value: '0,1,2,3,4,5,6'},
        {label: '每周一', value: '1'},
        {label: '每周二', value: '2'},
        {label: '每周三', value: '3'},
        {label: '每周四', value: '4'},
        {label: '每周五', value: '5'},
        {label: '每周六', value: '6'},
        {label: '每周日', value: '7'},  //此处7是因为0要出问题，提交时把7改成0即可
      ],

      // 表单数据
      formData: {
        school_id: '',
        term_id: '',
        schedule: [
          {
            week_range: [], //		时间 -- 天数
            start_at: '', //	时间 -- 开始时间 -- 时分
            end_at: '' //	时间 -- 结束时间 -- 时分
          }
        ],
      },
      // 表单验证规则
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        term_id: [{required: true, message: '请选择咨询周期', trigger: 'change'}],
      },
      startDefaultValue: new Date('2000-1-1 00:00:00'),
    }
  },
  created() {
    this.getSchoolConfig()
  },
  methods: {
    getSchoolConfig() {
      this.$_axios.get('site/school').then(res => {
        this.schoolConfig = res.data.data
      })
    },

    schoolChange(e){
      this.formData.term_id = '';
      this.$_axios2.get('api/evaluation/counseling-setting/term-enumeration?school_id=' + e).then(res => {
        console.log(res)
        if (res.data.status === 0) {
          this.periodConfig = res.data.data
        }
      })
    },

    dayChange(e, index) {
      if (!e.length) return;
      let i = e.slice(-1).join()

      if (i === '0,1,2,3,4,5,6') {
        this.formData.schedule[index].week_range = ['0,1,2,3,4,5,6']
      } else {
        if (!e.includes('0,1,2,3,4,5,6')) return;
        let arr = [];
        e.forEach(item => {
          if (item !== '0,1,2,3,4,5,6') {
            arr.push(item)
          }
        })
        this.formData.schedule[index].week_range = arr
      }
    },

    // 添加
    addTime() {
      this.formData.schedule.push({
        week_range: [],
        start_at: '',
        end_at: ''
      })
    },

    // 删除
    deleteTime(index) {
      this.$confirm('确认删除?','提示',{type:'warning'}).then(res=>{
        if (this.formData.schedule.length > 1) {
          this.formData.schedule.splice(index, 1)
        } else {
          this.$message.error('至少保留一条咨询时间')
        }
      })
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let params = JSON.parse(JSON.stringify(this.formData))
          this.formData.schedule.forEach((item, index) => {
            item.week_range.forEach((_item, _index) => {
              if (_item === '7') {
                params.schedule[index].week_range[_index] = '0'
              }
              if(_item === '0,1,2,3,4,5,6'){
                params.schedule[index].week_range = [0,1,2,3,4,5,6]
              }
            })
          })
          this.$_axios2.post('api/evaluation/counseling-setting/teacher-schedule', {...params}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '新增成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .yyy .el-form-item__label {
  line-height: 72rem !important;
}

::v-deep .el-form .yyy2 .el-input {
  width: 150rem !important;
}

.yyy {
  overflow: hidden;
}

.yyy1 .el-form-item {
  margin-bottom: 0;
}


.knowledge {
  margin-bottom: 24rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .knowledge_box {
    display: flex;
    align-items: center;
    height: 72rem;
    padding: 0 40rem;
    background-color: #f4f4f4;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
